const BIBLE_DATA_URL = '/bible-data/json/ESV.json';

export async function parseBibleData() {
  try {
    console.log('Fetching Bible data from JSON file');
    const response = await fetch(BIBLE_DATA_URL);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const data = await response.json();
    console.log('Raw Bible data:', data);

    const parsedData = {
      books: Object.entries(data.books).map(([bookName, chapters]) => ({
        chapter: bookName,
        verses: chapters
      })),
      meta: data.meta,
      version: data.version,
      versionName: data.versionName
    };

    console.log('Parsed Bible Data:', parsedData);
    return parsedData;
  } catch (error) {
    console.error('Error parsing Bible data:', error);
    throw error;
  }
}

export function getChapter(book, chapter, bibleData) {
  if (bibleData && bibleData.books && bibleData.books[book] && bibleData.books[book][chapter - 1]) {
    return {
      book,
      chapter,
      verses: bibleData.books[book][chapter - 1].map((verse, index) => ({
        number: index + 1,
        text: verse.map(word => word[0]).join(' ')
      }))
    };
  }
  return null;
}

export function searchBible(query, bibleData, book = null, chapter = null, scope = 'bible', chapterRange = '') {
  const results = [];
  const books = book ? [book] : Object.keys(bibleData.books);

  books.forEach(bookName => {
    let chapters;
    if (chapter) {
      chapters = [parseInt(chapter) - 1];
    } else if (scope === 'chapters' && chapterRange) {
      chapters = parseChapterRange(chapterRange);
    } else {
      chapters = [...Array(bibleData.books[bookName].length).keys()];
    }

    chapters.forEach(chapterIndex => {
      bibleData.books[bookName][chapterIndex].forEach((verse, verseIndex) => {
        const verseText = verse.map(word => word[0]).join(' ');
        if (verseText.toLowerCase().includes(query.toLowerCase())) {
          results.push({
            book: bookName,
            chapter: chapterIndex + 1,
            verse: verseIndex + 1,
            text: verseText,
          });
        }
      });
    });
  });

  return results;
}

function parseChapterRange(range) {
  return range.split(',').flatMap(part => {
    if (part.includes('-')) {
      const [start, end] = part.split('-').map(Number);
      return Array.from({length: end - start + 1}, (_, i) => start + i - 1);
    }
    return [Number(part) - 1];
  });
}

// Add more utility functions as needed
