import React from 'react';
import SearchComponent from '../components/Search';

function SearchPage({ bibleData }) {
  return (
    <div>
      <h1>Search the Bible</h1>
      <SearchComponent bibleData={bibleData} />
    </div>
  );
}

export default SearchPage;
