import React, { useContext } from 'react';
import { ThemeContext } from '../contexts/ThemeContext';

const ThemeSwitcher = () => {
  const { setCurrentTheme } = useContext(ThemeContext);

  return (
    <select onChange={(e) => setCurrentTheme(e.target.value)}>
      <option value="warmParchment">Warm Parchment</option>
      <option value="modernMinimalist">Modern Minimalist</option>
      <option value="elegantSerif">Elegant Serif</option>
    </select>
  );
};

export default ThemeSwitcher;
