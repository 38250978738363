import React from 'react';
import TableOfContents from '../components/TableOfContents';

function Home({ bibleData }) {
  return (
    <div>
      <h1>Welcome to the Online Bible</h1>
      <TableOfContents bibleData={bibleData} />
    </div>
  );
}

export default Home;
