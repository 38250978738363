import React from 'react';
import { Link } from 'react-router-dom';
import ThemeSwitcher from './ThemeSwitcher';
import styles from './Layout.module.css';

const Layout = ({ children }) => {
  return (
    <div className={styles.layout}>
      <header className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.logo}>
            <Link to="/">Bible App Logo</Link>
          </div>
          <nav className={styles.nav}>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/search">Search</Link></li>
            </ul>
          </nav>
          <div className={styles.userModule}>
            <button className={styles.loginButton}>Login</button>
            <button className={styles.profileButton}>Profile</button>
          </div>
          <ThemeSwitcher />
        </div>
      </header>
      <main className={styles.main}>{children}</main>
      <footer className={styles.footer}>© 2023 Online Bible Project</footer>
    </div>
  );
};

export default Layout;
