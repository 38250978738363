import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { searchBible } from '../utils/bibleParser';

function Search({ bibleData, book = null, chapter = null }) {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [searchScope, setSearchScope] = useState(book ? (chapter ? 'chapter' : 'book') : 'bible');
  const [chapterRange, setChapterRange] = useState('');

  const handleSearch = () => {
    const searchResults = searchBible(query, bibleData, book, chapter, searchScope, chapterRange);
    setResults(searchResults);
  };

  const inputStyle = {
    margin: '5px',
    padding: '5px',
    fontSize: '16px',
  };

  const selectStyle = {
    ...inputStyle,
    minWidth: '200px', // Increased width
  };

  const chapterInputStyle = {
    ...inputStyle,
    width: '300px', // Wider input for chapter range
  };

  function highlightText(text, highlight) {
    if (!highlight.trim()) {
      return <span>{text}</span>;
    }
    const regex = new RegExp(`(${highlight})`, 'gi');
    const parts = text.split(regex);
    return (
      <span>
        {parts.filter(String).map((part, i) => 
          regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>
        )}
      </span>
    );
  }

  return (
    <div style={{ margin: '20px 0' }}>
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Enter search query"
        style={inputStyle}
      />
      <select 
        value={searchScope} 
        onChange={(e) => setSearchScope(e.target.value)}
        style={selectStyle}
      >
        {!book && <option value="bible">Whole Bible</option>}
        {book && !chapter && <option value="book">Entire Book</option>}
        {book && !chapter && <option value="chapters">Select Chapters</option>}
        {chapter && <option value="chapter">This Chapter</option>}
        {chapter && <option value="book">Entire Book</option>}
      </select>
      {searchScope === 'chapters' && (
        <input 
          type="text" 
          value={chapterRange}
          onChange={(e) => setChapterRange(e.target.value)}
          placeholder="Enter chapter numbers (e.g., 1,3,5-7)" 
          style={chapterInputStyle}
        />
      )}
      <button onClick={handleSearch} style={inputStyle}>Search</button>
      <div style={{ marginTop: '20px' }}>
        {results.map((result, index) => (
          <div key={index} style={{ margin: '10px 0' }}>
            <Link to={`/read/${result.book}/${result.chapter}?verse=${result.verse}&highlight=${encodeURIComponent(query)}`}>
              {result.book} {result.chapter}:{result.verse}
            </Link>
            <p>{highlightText(result.text, query)}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Search;
