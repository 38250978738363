import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext';  // Make sure this import is correct
import Layout from './components/Layout';
import Home from './pages/Home';
import SearchPage from './pages/Search';
import BibleReader from './pages/BibleReader';
import BookLanding from './pages/BookLanding';
import { parseBibleData } from './utils/bibleParser';
import './App.css';

function App() {
  const [bibleData, setBibleData] = useState(null);

  useEffect(() => {
    async function fetchBibleData() {
      try {
        const data = await parseBibleData();
        console.log('Bible data in App:', data);
        setBibleData(data);
      } catch (error) {
        console.error("Error setting Bible data:", error);
      }
    }
    fetchBibleData();
  }, []);

  if (!bibleData) {
    return <div>Loading Bible data...</div>;
  }

  return (
    <ThemeProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home bibleData={bibleData} />} />
            <Route path="/search" element={<SearchPage bibleData={bibleData} />} />
            <Route path="/book/:book" element={<BookLanding bibleData={bibleData} />} />
            <Route path="/read/:book/:chapter" element={<BibleReader bibleData={bibleData} />} />
          </Routes>
        </Layout>
      </Router>
    </ThemeProvider>
  );
}

export default App;
