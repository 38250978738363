import React from 'react';
import { useParams, Link } from 'react-router-dom';
import Search from '../components/Search';

function BookLanding({ bibleData }) {
  const { book } = useParams();
  
  if (!bibleData || !bibleData.books) {
    return <div>Loading book data...</div>;
  }

  const normalizeBookName = (name) => {
    return name.replace(/^(\d+)\s+/, (match, number) => {
      const roman = ['I', 'II', 'III', 'IV', 'V'];
      return roman[parseInt(number) - 1] + ' ';
    });
  };

  const bookData = bibleData.books.find(b => normalizeBookName(b.chapter) === normalizeBookName(book));

  if (!bookData) {
    return <div>Book not found</div>;
  }

  const chapterCount = bookData.verses.length;

  const renderChapterGroups = () => {
    const groups = [];
    for (let i = 0; i < chapterCount; i += 10) {
      const group = Array.from({ length: Math.min(10, chapterCount - i) }, (_, index) => i + index + 1);
      groups.push(
        <div key={i} style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginBottom: '20px' }}>
          {group.map((chapterNum) => (
            <Link 
              key={chapterNum} 
              to={`/read/${book}/${chapterNum}`}
              style={{ padding: '5px 10px', border: '1px solid #ccc', borderRadius: '5px', textDecoration: 'none' }}
            >
              {chapterNum}
            </Link>
          ))}
        </div>
      );
    }
    return groups;
  };

  return (
    <div>
      <h2>Table of Contents for {bookData.chapter}</h2>
      <Search bibleData={bibleData} book={bookData.chapter} />
      <div>
        <h3>Reading Options:</h3>
        <ul>
          <li>
            <Link to={`/read/${book}/1`}>Read by Chapter</Link>
          </li>
          <li>
            <Link to={`/read/${book}/all`}>Read Entire Book</Link>
          </li>
        </ul>
      </div>
      <div>
        <h3>Chapters:</h3>
        {renderChapterGroups()}
      </div>
    </div>
  );
}

export default BookLanding;
