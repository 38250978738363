import React from 'react';
import { Link } from 'react-router-dom';
import styles from './TableOfContents.module.css';

const oldTestamentBooks = [
  "Genesis", "Exodus", "Leviticus", "Numbers", "Deuteronomy", "Joshua", "Judges", "Ruth", "1 Samuel", "2 Samuel",
  "1 Kings", "2 Kings", "1 Chronicles", "2 Chronicles", "Ezra", "Nehemiah", "Esther", "Job", "Psalms", "Proverbs",
  "Ecclesiastes", "Song of Solomon", "Isaiah", "Jeremiah", "Lamentations", "Ezekiel", "Daniel", "Hosea", "Joel",
  "Amos", "Obadiah", "Jonah", "Micah", "Nahum", "Habakkuk", "Zephaniah", "Haggai", "Zechariah", "Malachi"
];

const newTestamentBooks = [
  "Matthew", "Mark", "Luke", "John", "Acts", "Romans", "1 Corinthians", "2 Corinthians", "Galatians", "Ephesians",
  "Philippians", "Colossians", "1 Thessalonians", "2 Thessalonians", "1 Timothy", "2 Timothy", "Titus", "Philemon",
  "Hebrews", "James", "1 Peter", "2 Peter", "1 John", "2 John", "3 John", "Jude", "Revelation"
];

function TableOfContents({ bibleData }) {
  const renderBookList = (books) => (
    <ul className={styles.bookList}>
      {books.map((book) => (
        <li key={book} className={styles.bookItem}>
          <Link to={`/book/${book}`} className={styles.bookLink}>
            {book}
          </Link>
        </li>
      ))}
    </ul>
  );

  return (
    <div className={styles.tableOfContents}>
      <h2 className={styles.title}>Table of Contents</h2>
      <div className={styles.testaments}>
        <div className={styles.testament}>
          <h3 className={styles.testamentTitle}>Old Testament</h3>
          {renderBookList(oldTestamentBooks)}
        </div>
        <div className={styles.testament}>
          <h3 className={styles.testamentTitle}>New Testament</h3>
          {renderBookList(newTestamentBooks)}
        </div>
      </div>
    </div>
  );
}

export default TableOfContents;
