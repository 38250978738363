import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

const themes = {
  warmParchment: {
    primaryColor: '#8B4513',
    backgroundColor: '#F5E6D3',
    textColor: '#4A4A4A',
    highlightColor: '#D2B48C',
    fontFamily: "'Merriweather', serif",
  },
  modernMinimalist: {
    primaryColor: '#3E4A61',
    backgroundColor: '#F9F9F9',
    textColor: '#333333',
    highlightColor: '#E6E6E6',
    fontFamily: "'Roboto', sans-serif",
  },
  elegantSerif: {
    primaryColor: '#5B3758',
    backgroundColor: '#FFF8F0',
    textColor: '#2C2C2C',
    highlightColor: '#E8D0D0',
    fontFamily: "'Lora', serif",
  },
};

export const ThemeProvider = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme || 'warmParchment';
  });

  useEffect(() => {
    localStorage.setItem('theme', currentTheme);
    const theme = themes[currentTheme];
    document.body.style.setProperty('--primary-color', theme.primaryColor);
    document.body.style.setProperty('--background-color', theme.backgroundColor);
    document.body.style.setProperty('--text-color', theme.textColor);
    document.body.style.setProperty('--highlight-color', theme.highlightColor);
    document.body.style.fontFamily = theme.fontFamily;
    document.body.style.backgroundColor = theme.backgroundColor;
    document.body.style.color = theme.textColor;
  }, [currentTheme]);

  return (
    <ThemeContext.Provider value={{ theme: themes[currentTheme], setCurrentTheme, themes }}>
      {children}
    </ThemeContext.Provider>
  );
};
