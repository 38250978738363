import React, { useState } from 'react';
import axios from 'axios';
import './ChatGptPanel.css';

function ChatGptPanel({ selectedText, onClose }) {
  const [gptResponse, setGptResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleGptRequest = async (action) => {
    setIsLoading(true);
    setError(null);

    let prompt = '';
    switch (action) {
      case 'rephrase':
        prompt = `Rephrase this in modern language: "${selectedText}"`;
        break;
      case 'explain':
        prompt = `Explain the meaning of: "${selectedText}"`;
        break;
      case 'compare':
        prompt = `Compare "${selectedText}" to other Bible versions`;
        break;
      default:
        prompt = selectedText;
    }

    try {
      console.log('API Key:', process.env.REACT_APP_OPENAI_API_KEY); // For debugging
      const response = await axios.post(
        'https://api.openai.com/v1/engines/text-davinci-002/completions',
        {
          prompt: prompt,
          max_tokens: 150,
          n: 1,
          stop: null,
          temperature: 0.7,
        },
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setGptResponse(response.data.choices[0].text.trim());
    } catch (err) {
      console.error('Full error:', err); // Log the full error for debugging
      setError('An error occurred while fetching the response. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="chat-gpt-panel">
      <h3>ChatGPT Options</h3>
      <button onClick={() => handleGptRequest('rephrase')} disabled={isLoading}>
        Rephrase in modern language
      </button>
      <button onClick={() => handleGptRequest('explain')} disabled={isLoading}>
        Explain meaning
      </button>
      <button onClick={() => handleGptRequest('compare')} disabled={isLoading}>
        Compare to other versions
      </button>
      <div>
        <h4>Response:</h4>
        {isLoading && <p>Loading...</p>}
        {error && <p className="error">{error}</p>}
        {!isLoading && !error && <p>{gptResponse}</p>}
      </div>
      <button onClick={onClose}>Close</button>
    </div>
  );
}

export default ChatGptPanel;
